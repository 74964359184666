import Vue from 'vue';
import Vuex from 'vuex';

import postersData from '@/assets/data/posters.json';

Vue.use(Vuex);

export const TRANSITION_TIME = 800;

export default new Vuex.Store({
    state() {
      return {
        frame: 1,
        forceFrame: false,
        inTransition: false,
        zoomedIn: false,
        uiPoster: null,
        sortedPosters: [],

        showTutorialPopup: false,
      };
    },
    getters: {
      hasPreviousPoster(state) {
        const idx = state.sortedPosters.findIndex((p) => p.id === state.uiPoster);
        return idx > 0;
      },
      hasNextPoster(state) {
        const idx = state.sortedPosters.findIndex((p) => p.id === state.uiPoster);
        return idx < state.sortedPosters.length - 1;
      },
    },
    mutations: {
      initializePosters(state) {
        const k = Object.keys(postersData);
        k.sort();
        state.sortedPosters = k.reduce((acc, roomId) => {
          if (roomId === 's00' || roomId === 's09') return acc;
          const x = Object.keys(postersData[roomId].affiches);
          x.sort();
          return [...acc, ...x.map((t) => ({
            id: t,
            ...postersData[roomId].affiches[t],
          }))];
        }, []);
      },
      setFrame (state, data) {
        state.frame = data.frame;
        if (data.force)
          state.forceFrame = true;
        else
          state.forceFrame = false;
      },
      setZoomedIn (state, { zoomedIn }) {
        state.zoomedIn = zoomedIn;
      },
      setUiPoster (state, { uiPoster }) {
        state.uiPoster = uiPoster;
      },
      offsetPoster (state, { offset }) {
        const idx = state.sortedPosters.findIndex((p) => p.id === state.uiPoster);
        if (offset < 0 && idx === 0) return;
        if (offset > 0 && idx === state.sortedPosters.length - 1) return;
        const newIdx = idx + offset;
        state.uiPoster = state.sortedPosters[newIdx].id;
      },
      startTransition (state) {
        state.inTransition = true;
        window.setTimeout(() => {
          state.inTransition = false;
        }, TRANSITION_TIME);
      },

      setShowTutorialPopup (state, { showTutorialPopup }) {
        state.showTutorialPopup = showTutorialPopup;
      },
    },
});
