<template>
  <div id="three-js-env" :class="{ grabbed: isUserInteracting }">
    <video id="three-js-video" hidden>
      <source v-if="currentVideo" id="video-mp4"  :src="`${currentVideo}.mp4`" type="video/mp4" />
    </video>
    <canvas id="three-js-canvas" hidden></canvas>
    <div id="viewport"></div>
  </div>
</template>

<script>
/* eslint-disable */
import * as THREE from 'three';
import { CSS3DRenderer, CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js';
import { TRANSITION_TIME } from '@/store.js';

import cameraData from '@/assets/data/camera_data.json';
import connectivity from '@/assets/data/connectivity.json';
import roomToFrame from '@/assets/data/room_to_frame.json';

const getElementRelativePosition = (viewRadius, camLoc, coords) => {
  const x = -(coords[0] - camLoc[0]);
  const y = coords[2] - camLoc[2];
  const z = coords[1] - camLoc[1];
  const l = Math.sqrt(x * x + y * y + z * z);
  const normX = x * viewRadius / l;
  const normY = y * viewRadius / l;
  const normZ = z * viewRadius / l;
  return [x, y, z, l, normX, normY,normZ];
};
const getWaypointData = (viewRadius, frame, currentPoint, waypointType) => {
  let pNext;
  if (frame === 40) { // HACK: move pts to avoid collision with decor
    pNext = [7.935334205627441, 18.263270568847656, 1.5999999046325684];
  }
  else if (frame === 42) {
    pNext = [7.935334205627441, 12.89565143585205, 1.5999999046325684];
  }
  else
    pNext = cameraData.frame_info.find((x) => x.frame === frame).cam_location;
  let x = -(pNext[0] - currentPoint[0]);
  let y = -1.6;
  let z = pNext[1] - currentPoint[1];
  const extraPos = [x, y, z];
  const l = Math.sqrt(x * x + y * y + z * z);
  x = x * viewRadius / l;
  y = y * viewRadius / l;
  z = z * viewRadius / l;
  return [frame, [x, y, z, l], extraPos, waypointType];
};

const setCanvasDimensions = (canvas, width, height, set2dTransform = false) => {
  const ratio = window.devicePixelRatio;
  canvas.width = width * ratio;
  canvas.height = height * ratio;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
  if (set2dTransform) {
    canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
  }
}

export default {
  name: 'ThreeJSEnv',
  data() {
    return {
      viewer: null,
      viewerIndicatorRotation: 0,
      viewportRotation: '',

      currentVideo: null,

      pois: [],
      moveObjs: [],

      viewRadius: 100,

      isUserInteracting: false,
      onPointerDownMouseX: 0,
      onPointerDownMouseY: 0,
      lon: roomToFrame.s01.lon,
      onPointerDownLon: 0,
      lat: roomToFrame.s01.lat,
      onPointerDownLat: 0,
      phi: 0,
      theta: 0,

      fov: 70,
    };
  },
  computed: {
    frame() { return this.$store.state.frame; },
    forceFrame() { return this.$store.state.forceFrame; },
    frameStr() { return this.frame.toString().padStart(2, '0'); },
    nextPoints() {
      const { direct, long } = connectivity[this.frame] || [];
      const pCur  = cameraData.frame_info.find((x) => x.frame === this.frame).cam_location;
      return [
        ...direct.map((t) => getWaypointData(this.viewRadius, t, pCur, 'direct')),
        ...long.map((t) => getWaypointData(this.viewRadius, t, pCur, 'long'))
      ];
    },
    info() {
      const frameInfo = cameraData.frame_info.find((f) => f.frame === this.frame) || null;
      if (frameInfo === null) return {};
      const camLoc = frameInfo.cam_location;

      const texts = [];
      for (const textRef of frameInfo.visible_room_texts) {
        texts.push({
          coords: getElementRelativePosition(
            this.viewRadius,
            camLoc,
            cameraData.room_info[textRef].text_coordinates),
          rot: cameraData.room_info[textRef].text_rotation,
          id: textRef,
        });
      }

      const posters = [];
      for (const posterRef of frameInfo.visible_posters) {
        posters.push({
          coords: getElementRelativePosition(
            this.viewRadius,
            camLoc,
            cameraData.poster_info[posterRef].poster_coordinates),
          id: posterRef,
        });
      }

      const labels = [];
      for (const labelRef of frameInfo.visible_labels) {
        labels.push({
          coords: getElementRelativePosition(
            this.viewRadius,
            camLoc,
            cameraData.poster_info[labelRef].label_coordinates),
          id: labelRef,
        });
      }

      return {
        texts: { type: 'room', objects: texts },
        posters: { type: 'poster', objects: posters },
        labels: { type: 'label', objects: labels },
      };
    },
  },
  mounted() {
    this.init();
    this.animate();
    this.loadPOIs();
  },
  watch: {
    frame(newFrame, oldFrame) {
      if (newFrame !== oldFrame) {
        if (this.forceFrame) {
          this.removePOIs();
          this.imageMaterial.map = this.texLoader.load(this.getFrameTexture(newFrame));
          this.imageMaterial.map.needsUpdate = true;
          this.sphere360.material = this.imageMaterial;
        }
        this.loadPOIs();
      }
    },
  },
  beforeDestroy() {
    this.container.removeEventListener('pointerdown', this.onPointerDown);
    document.removeEventListener('dragover', this.onDragOver);
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('pointermove', this.onPointerMove);
    document.removeEventListener('pointerup', this.onPointerUp);
  },
  methods: {
    getFrameTexture(frame) {
      const frameStr = frame.toString().padStart(2, '0');
      return require(`@/assets/data/stills/f${frameStr}.png`);
    },

    setup360Video() {
      if (this.videoTexture) this.videoTexture.dispose();
      if (this.videoMaterial) this.videoMaterial.dispose();
      this.videoTexture = new THREE.VideoTexture(this.videoDiv);
      this.videoMaterial = new THREE.MeshBasicMaterial({ map: this.videoTexture });
    },

    init() {
      this.camera = new THREE.PerspectiveCamera(this.fov, window.innerWidth / window.innerHeight, 0.01, this.viewRadius * 2 + 5);
      this.scene = new THREE.Scene(); // for 360 texture
      this.cssScene = new THREE.Scene(); // for interactable HTML elements

      // setup move point geom + materials
      this.movePointGeometry = new THREE.RingGeometry(5.25, 6, 32);
      this.movePointMaterial = new THREE.MeshBasicMaterial({
        color: 0xffffff, side: THREE.FrontSide,
        transparent: true, opacity: 0.5,
      });
      this.movePointMaterialHover = new THREE.MeshBasicMaterial({
        color: 0x8acdc8, side: THREE.FrontSide,
        transparent: true, opacity: 0.65,
      });

      // setup video tex
      this.videoDiv = document.getElementById('three-js-video');
      this.setup360Video();

      // setup image tex
      this.texLoader = new THREE.TextureLoader();
      this.imageMaterial = new THREE.MeshBasicMaterial({ map: this.texLoader.load(this.getFrameTexture(this.frame)) });

      // add 360 sphere
      const geometry360 = new THREE.SphereGeometry(this.viewRadius, 60, 40);
      geometry360.scale(-1, 1, 1); // invert the geometry on the x-axis so that all of the faces point inward
      this.sphere360 = new THREE.Mesh(geometry360, this.imageMaterial);
      this.scene.add(this.sphere360);
      // ------

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      this.cssRenderer = new CSS3DRenderer();
      this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
      this.cssRenderer.domElement.style.position = 'absolute';
      this.cssRenderer.domElement.style.zIndex = 99999;
      this.cssRenderer.domElement.style.top = 0;

      const container = document.getElementById('viewport');
      container.appendChild(this.renderer.domElement);
      container.appendChild(this.cssRenderer.domElement);

      container.style.touchAction = 'none';
      container.addEventListener('pointerdown', this.onPointerDown);
      document.addEventListener('dragover', this.onDragOver);
      window.addEventListener('resize', this.onWindowResize);
      document.addEventListener('pointermove', this.onPointerMove);
      document.addEventListener('pointerup', this.onPointerUp);

      this.container = container;
    },

    onDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },
    onWindowResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
      setCanvasDimensions(this.renderer.domElement, width, height);
      this.cssRenderer.setSize(width, height);
      setCanvasDimensions(this.cssRenderer.domElement, width, height);
    },
    onPointerDown(event) {
      if (event.isPrimary === false) return;
      this.isUserInteracting = true;

      this.onPointerDownMouseX = event.clientX;
      this.onPointerDownMouseY = event.clientY;

      this.onPointerDownLon = this.lon;
      this.onPointerDownLat = this.lat;
    },
    onPointerMove(event) {
      // move around if mouse is down
      if (this.isUserInteracting) {
        this.lon = (this.onPointerDownMouseX - event.clientX) * 0.1 + this.onPointerDownLon;
        this.lat = (event.clientY - this.onPointerDownMouseY) * 0.1 + this.onPointerDownLat;
      }
    },
    onPointerUp(event) {
      if (event.isPrimary === false) return;
      this.isUserInteracting = false;
    },
    animate() {
      requestAnimationFrame( this.animate );
      this.update();
    },
    update() {
      this.lat = Math.max( - 85, Math.min( 85, this.lat ) );
      this.phi = THREE.MathUtils.degToRad( 90 - this.lat );
      this.theta = THREE.MathUtils.degToRad( this.lon );

      const x = 500 * Math.sin( this.phi ) * Math.cos( this.theta );
      const y = 500 * Math.cos( this.phi );
      const z = 500 * Math.sin( this.phi ) * Math.sin( this.theta );

      this.camera.lookAt(x, y, z);

      this.renderer.render(this.scene, this.camera);
      this.cssRenderer.render(this.cssScene, this.camera);
    },
    setLonLat(lon, lat) {
      this.lon = lon;
      this.lat = lat;
      this.update();
    },

    loadPOIs() {
      for (const key of ['texts', 'posters', 'labels']) {
        const { type, objects } = this.info[key];
        for (const obj of objects) {
          const [x, y, z] = obj.coords;
          if (key === 'texts')
            this.addPointOfInterest(obj.id, type, x, y, z, obj.rot);
          else
            this.addPointOfInterest(obj.id, type, x, y, z);
        }
      }
      for (const [c, [x, y, z], extraPos, waypointType] of this.nextPoints) {
        this.addPointOfInterest(c, 'move', x, y, z, extraPos, waypointType);
      }
    },
    addPointOfInterest(id, type, x, y, z, extraPos, waypointType) {
      const element = document.createElement('div');
      element.classList.add('point-of-interest');
      element.classList.add(type);
      const $this = this;
      element.onclick = (e) => {
        if (type === 'move') $this.moveTo(id, waypointType === 'direct');
        else {
          if (id === 's08_r01') // HACK: show same info as room 9 popup text
            $this.$emit('click-poi', { id: 's09', type: 'room', x: e.clientX, y: e.clientY });
          else
            $this.$emit('click-poi', { id, type, x: e.clientX, y: e.clientY });
        }
        e.stopPropagation();
      }

      const objectCSS = new CSS3DObject(element);
      const CSS_SIZES = {
        'poster': { x:   1, y: 1.5 },
        'label':  { x: 0.7, y: 0.7 },
        'room':   { x: 2.5, y: 2 },
        'move':   { x:  15, y: 15 },
      };
      element.style.width = `${CSS_SIZES[type].x}px`;
      element.style.height = `${CSS_SIZES[type].y}px`;
      objectCSS.position.set(x, y, z);
      if (type === 'poster' || type === 'label' || type === 'move')
        objectCSS.lookAt(this.camera.position);
      else if (type === 'room' && extraPos) {
        objectCSS.rotateY(THREE.MathUtils.degToRad(extraPos.y));
        objectCSS.rotateX(THREE.MathUtils.degToRad(extraPos.x));
      }
      this.cssScene.add(objectCSS);

      if (type === 'move') { // create circle as "real" 3D obj to avoid pixelation
        const [xx, yy, zz] = extraPos;
        const mesh = new THREE.Mesh(this.movePointGeometry, this.movePointMaterial);
        mesh.position.set(xx, yy, zz);
        const scale = (id === 40 || id === 42) ? 0.08 : ((id === 43 && this.frame === 39) ? 0.1 : 0.12);
        mesh.scale.set(scale, scale, scale);
        mesh.rotateX(-Math.PI / 2);
        this.scene.add(mesh);
        this.moveObjs.push(mesh);

        element.onmouseenter = () => {
          mesh.material = this.movePointMaterialHover;
        }
        element.onmouseleave = () => {
          mesh.material = this.movePointMaterial;
        }
      }

      this.pois.push(objectCSS);
    },
    removePOIs() {
      for (const poi of this.pois)
        this.cssScene.remove(poi);
      for (const obj of this.moveObjs)
        this.scene.remove(obj);
      this.pois = [];
      this.moveObjs = [];
    },

    moveTo(frame, isDirect) {
      const $this = this;
      if (isDirect) {
        const to = frame.toString().padStart(2, '0');
        this.currentVideo = `movies/visite_${this.frameStr}-${to}.v034`;
        this.setup360Video();
        this.videoDiv.onloadeddata = () => {
          $this.videoDiv.play();
          setTimeout(() => {
            // load video texture as active texture on 360 sphere
            $this.sphere360.material = $this.videoMaterial;
            $this.removePOIs();
            // prepare next frame (in image texture)
            $this.imageMaterial.map = $this.texLoader.load($this.getFrameTexture(frame));
            $this.imageMaterial.map.needsUpdate = true;
          }, 200);
        };
        this.videoDiv.load();
        this.videoDiv.onended = () => {
          $this.$store.commit('setFrame', { frame });
          $this.sphere360.material = $this.imageMaterial;
        }
      } else {
        this.$store.commit('startTransition');
        setTimeout(() => {
          $this.$store.commit('setFrame', { frame, force: true });
        }, TRANSITION_TIME / 2);
      }
    },
  },
}
</script>

<style>
#three-js-env {
  overflow: hidden;
  cursor: var(--cursor-hand-base--black);
}
#three-js-env.grabbed {
  cursor: var(--cursor-hand-grab--black);
}

.viewer-rotation-indicator {
  --indicator-size: 32px;
  opacity: 0.85;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(-50%, -100%);
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 256 256' style='enable-background:new 0 0 256 256;' xml:space='preserve'%3E %3Ccircle%0A%20%20%20class%3D%22st2%22%0A%20%20%20cx%3D%22127.53%22%0A%20%20%20cy%3D%22129.19%22%0A%20%20%20r%3D%2298.309998%22%0A%20%20%20id%3D%22circle26%22%20%2F%3E%0A%3Cpath%0A%20%20%20id%3D%22circle374%22%0A%20%20%20style%3D%22fill%3Anone%3Bstroke%3A%23ffffff%3Bstroke-width%3A3%22%0A%20%20%20class%3D%22st2%22%0A%20%20%20d%3D%22m%20209.7788%2C129.19%20c%202.32864%2C56.36392%20-62.54172%2C99.7089%20-113.724055%2C75.98798%20C%2043.090145%2C185.75983%2027.869409%2C109.24003%2069.371313%2C71.031317%20107.58003%2C29.529413%20184.09982%2C44.750146%20203.51798%2C97.714746%20l%204.68043%2C15.429304%20z%22%20%2F%3E%3Cpath%0A%20%20%20id%3D%22circle1659%22%0A%20%20%20style%3D%22fill%3A%23ffffff%3Bstroke-width%3A3%22%0A%20%20%20class%3D%22st2%22%0A%20%20%20inkscape%3Atransform-center-x%3D%22-40.987646%22%0A%20%20%20inkscape%3Atransform-center-y%3D%220.18483241%22%0A%20%20%20d%3D%22m%20198.83365%2C88.158738%20c%2014.59845%2C24.293102%2015.06414%2C59.779192%20-0.82058%2C81.775042%20-23.46966%2C-13.55021%20-46.93932%2C-27.10043%20-70.40898%2C-40.65064%2023.74319%2C-13.70813%2047.48637%2C-27.41627%2071.22956%2C-41.124402%20z%22%20%2F%3E %3C/svg%3E");  
  width: var(--indicator-size);
  height: var(--indicator-size);
  z-index: 9998;
  overflow: hidden;
}

#viewport {
  position: absolute;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
  user-select: none;
}

.point-of-interest {
  pointer-events: all;
}
.point-of-interest.poster,
.point-of-interest.label {
  cursor: var(--icon-eye);
}
.point-of-interest.room {
  cursor: var(--icon-info);
}
.point-of-interest.move {
  cursor: var(--icon-feet);
}
</style>
