<template>
  <div id="mobile-warning">
    <div id="mobile-warning-content">
      <div id="mobile-warning-title">
        <img id="logo" src="logo-ijv_bleu.png" />
        <div id="text">Musée virtuel<br />de l'affiche de cinéma</div>
      </div>
      <div id="mobile-warning-info">
        <div>Cette visite virtuelle n'est pas encore disponible sur mobile et tablette...</div>
        <div>Découvrez-là sur votre ordinateur !</div>
        <div id="mobile-warning-img">
          <img src="vigo-nomobile.png" :class="{ 'small-height': hasSmallWindowHeight }" />
        </div>
      </div>

      <div id="back-btn-wrapper">
        <a href="https://www.inst-jeanvigo.eu/">
          <button id="back-btn">
            <div id="back-btn-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                <path d="m 42.687188,134.63268 c 20.805716,20.75839 41.51917,41.61383 62.382562,62.31155 6.14578,5.57182 17.03789,-0.25745 15.79465,-8.46455 -0.93741,-6.20856 -7.05233,-9.45064 -10.80614,-13.94521 -12.320099,-12.32009 -24.640201,-24.64019 -36.960302,-36.96029 47.659182,0.21365 95.318352,0.4273 142.977532,0.64095 0.0267,-6.47435 0.0534,-12.94871 0.0801,-19.42306 -47.75837,-0.21365 -95.51674,-0.42729 -143.275112,-0.64094 15.108142,-15.15542 30.313252,-30.218654 45.360732,-45.431739 5.57181,-6.145789 -0.25745,-17.03789 -8.46456,-15.794649 -6.20856,0.937413 -9.45064,7.052333 -13.945206,10.806144 -17.927127,17.976973 -35.956666,35.856864 -53.819624,53.894664 -3.117662,3.72213 -2.823121,9.63009 0.675368,13.00713 z" />
              </svg>
            </div>
            <div id="back-btn-text">retour</div>
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileWarning',
  computed: {
    hasSmallWindowHeight() { return window.innerHeight < 600 },
  }
}
</script>

<style scoped>
#mobile-warning {
  background: url('~@/assets/vigo-fond-avertissement.jpg');
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  display: grid;
  place-items: center;
  color: var(--c-primary);
}

#mobile-warning-content {
  width: 66vw;
  max-width: 780px;
}

#mobile-warning-title {
  display: grid;
  grid-template-columns: auto 1fr;
}

#mobile-warning-title #logo {
  width: auto;
  height: 48px;
}
#mobile-warning-title #text {
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-left: 1.5rem;
  font-family: 'ArimaMadurai';
  line-height: 1.1;
}

#mobile-warning-info {
  border-top: 1px solid var(--c-secondary);
  padding: 1rem 0;
  margin: 1rem 0;
  font-size: 1.1rem;
}

#mobile-warning-img {
  display: flex;
  justify-content: center;
}

#mobile-warning-img img:not(.small-height) {
  height: auto;
  width: 100%;
}
#mobile-warning-img img.small-height {
  width: 40%;
}

#back-btn-wrapper {
  padding-top: 2rem;
  border-top: 1px solid var(--c-secondary);
}
#back-btn-wrapper a {
  text-decoration: none;
}

#back-btn {
  --btn-height: 32px;
  height: var(--btn-height);
  background-color: var(--c-secondary);
  color: #FCF6F2;
  border: 0;
  border-radius: 9999px;
  display: grid;
  place-items: center;
  grid-template-columns: auto 1fr;
  margin: 0 auto;
  text-transform: uppercase;
  padding: 0;
  font-size: 1.2rem;
  font-family: 'ArimaMadurai-Regular';
  font-weight: 600;
  cursor: var(--cursor-hand-click--white);
  padding-left: calc(var(--btn-height) * 0.1);
  padding-right: calc(var(--btn-height) * 0.3);
}
#back-btn:hover {
  background-color: var(--c-tertiary);
}
#back-btn:active {
  background-color: var(--c-tertiary--dark);
}

#back-btn-text {
  height: 90%;
  padding: 0 1rem;
}

#back-btn-icon {
  border-radius: 50%;
  background-color: #111111;
  width: calc(var(--btn-height) * 0.8);
  height: calc(var(--btn-height) * 0.8);
}
#back-btn-icon svg {
  fill: #FCF6F2;
}
</style>
