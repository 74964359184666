<template>
  <div>
    <div id="ui-overlay" @click="$emit('close')"></div>
    <div id="intro-panel">
      <div class="catalogue-section">
        <img id="logo" src="logo-ijv_bleu.png" width="64" height="auto" />
        <div id="catalogue-room-text">
          <div id="catalogue-room-text--content">
            <div class="catalogue-separator"></div>
            <div class="intro-section">Aide à la navigation</div>
            <div class="intro-subsection">Interactions dans l'espace de l'exposition</div>
              <img class="popup-img" src="vigo-tuto1.jpg" />
            <div class="intro-subsection">Interactions dans l'écran "œuvre"</div>
              <img class="popup-img" src="vigo-tuto2.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialPanel',
}
</script>
