<template>
  <div id="catalogue" :class="{ 'zoomed-in': zoomedIn }">
    <div id="catalogue-container" @click.self="$emit('close')">
      <PosterDetails
        v-if="currentPoster"
        :poster="currentPoster"
        @close="$emit('close')"
      />
      <template v-else-if="currentRoom">
        <div class="catalogue-section catalogue-section--fixed" @click.self="$emit('close')">
          <div class="catalogue-room">
            <div class="catalogue-room-label">SALLE {{ getRoomNumber(room) }} |</div>
            <div class="catalogue-room-name">{{ postersData[room].nom }}</div>
          </div>

          <div id="catalogue-room-text">
            <div id="catalogue-room-text--content">
              <div class="catalogue-separator"></div>
              <div v-html="postersData[room].texte_mur"></div>
              <div class="intro-section">Pour aller plus loin...</div>
              <div v-html="postersData[room].texte"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="roomId of roomsToShow" :key="roomId" class="catalogue-section" @click.self="$emit('close')">
          <div class="catalogue-room">
            <div class="catalogue-room-label">SALLE {{ getRoomNumber(roomId) }} |</div>
            <div class="catalogue-room-name">{{ postersData[roomId].nom }}</div>
          </div>
    
          <div class="catalogue-images">
            <img
              class="catalogue-image"
              v-for="posterId in Object.keys(postersData[roomId].affiches || {})"
              :key="posterId"
              :src="`${baseUrl}thumbs/${postersData[roomId].affiches[posterId].image.replace('.jpg', '.webp')}`"
              rel="preload"
              @click="setPoster(posterId)" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import postersData from '@/assets/data/posters.json';

import PosterDetails from './PosterDetails.vue';

export default {
  name: 'Catalogue',
  components: { PosterDetails },
  props: {
    room: { type: [String, null], default: null },
  },
  data() {
    return {
      postersData,
    };
  },
  computed: {
    poster() { return this.$store.state.uiPoster; },
    baseUrl() {
      let url = process.env.BASE_URL;
      if (!url.endsWith('/')) url += '/';
      return url;
    },
    zoomedIn() {
      return this.$store.state.zoomedIn;
    },
    roomsToShow() {
      const r = Object.keys(postersData);
      return r.slice(1, r.length - 1);
    },
    currentRoom() {
      if (!this.room) return null;
      return postersData[this.room];
    },
    currentPoster() {
      if (!this.poster) return null;
      const room = this.poster.split('_')[0];
      return postersData[room].affiches[this.poster] || null;
    },
  },
  methods: {
    getRoomNumber(id) {
      return parseInt(id.slice(1));
    },
    setPoster(posterId) {
      this.$store.commit('setUiPoster', { uiPoster: posterId });
    },
  },
}
</script>

<style>
#catalogue {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  padding: var(--pad);
}
#catalogue:not(.zoomed-in) {
  --pad: calc(2 * var(--poster-caret-size));
}
#catalogue.zoomed-in {
  --pad: 5vh;
}

#catalogue:not(.zoomed-in) #catalogue-container,
#catalogue:not(.zoomed-in) #details-container {
  height: calc(100vh - var(--pad) * 2);
}
.catalogue-section--fixed,
#catalogue-room-text {
  height: calc(100vh - 14vh);
  cursor: var(--cursor-hand-base--white);
}

#catalogue-container {
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
  position: relative;
  cursor: var(--cursor-close);
  scrollbar-width: var(--scrollbar-width);
  scrollbar-color: var(--c-secondary) transparent;
  height: 100%;
}
#catalogue-container::-webkit-scrollbar {
  width: var(--scrollbar-width);
}
#catalogue-container::-webkit-scrollbar-track {
  background: transparent;
}
#catalogue-container::-webkit-scrollbar-thumb {
  background-color: var(--c-secondary);
  border-radius: 8px;
}


.catalogue-section {
  display: grid;
  grid-template-columns: 3fr 4fr;
  align-items: center;
  cursor: var(--cursor-close);
}
.catalogue-section--fixed {
  height: 100%;
  overflow-y: hidden;
}

.catalogue-room {
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.1rem;
  color: var(--c-primary);
  cursor: var(--cursor-hand-base--white);
}
.catalogue-room-label {
  font-family: "Alegreya";
}
.catalogue-room-name {
  font-size: 1.3rem;
  font-family: "ArimaMadurai";
}
#catalogue-room-text {
  overflow-y: auto;
  scrollbar-width: var(--scrollbar-width);
  scrollbar-color: var(--c-secondary) transparent;
  padding-right: 1rem;
  text-align: justify;
}
#catalogue-room-text::-webkit-scrollbar {
  width: var(--scrollbar-width);
}
#catalogue-room-text::-webkit-scrollbar-track {
  background: transparent;
}
#catalogue-room-text::-webkit-scrollbar-thumb {
  background-color: var(--c-secondary);
  border-radius: 8px;
}
#catalogue-room-text--content {
  color: var(--c-primary);
  padding-right: 3rem;
}

.catalogue-separator {
  background-color: var(--c-secondary);
  height: 1px;
  width: 100%;
  margin-bottom: 2rem;
}

.catalogue-images {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0 2rem 0;
  row-gap: 1rem;
  column-gap: 1rem;
  pointer-events: auto;
  align-items: center;
}

.catalogue-section:not(:last-child) .catalogue-images {
  border-bottom: 1px solid var(--c-secondary);
  margin-bottom: 1rem;
}

.catalogue-image {
  height: 25vh;
  width: auto;
  cursor: var(--icon-eye);
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
.catalogue-image:hover {
  filter: brightness(110%);
  transform: scale(1.035);
}

.imgs-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.imgs-row img {
  width: 32%;
  height: auto;
}

.popup-credits {
  text-align: right;
  font-style: italic;
  margin-bottom: 1rem;
}
.popup-img {
  width: 100%;
}
.popup-img-caption {
  text-align: left;
  font-style: italic;
  font-size: 0.9rem;
}

.popup-img-caption ~.popup-img {
  margin-top: 0.5rem;
}
</style>
