<template>
  <div id="bottom-bar" :class="{ 'in-ui': inUI }">
    <div id="bottom-bar__gradient"></div>
    <div id="bottom-bar__info">
      <div class="logo">
        <a href="https://www.inst-jeanvigo.eu/">
          <img class="logo-image" src="logo-ijv.png" />
        </a>
        <div v-if="!zoomedIn" class="logo-title">collections permanentes | {{ roomName }}</div>
      </div>
      <div v-if="!zoomedIn">
        <div class="button" title="Introduction" v-title>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve" @click="$emit('open-intro')">
            <path d="M207.1,98.8l-44-40.5c-0.1-0.1-0.1-0.1-0.2-0.2l-2.8-2.5l-1.5-1.4c0,0,0,0,0,0l-23-20.9c-4-3.7-10.1-3.7-14.2,0
              L94.1,58.2C94,58.2,94,58.3,94,58.3l-44,40.5c-5.1,4.6-8,11.2-8,18.1v110h55.4l0-11.7V149c0-4.6,3.7-8.3,8.3-8.3h45.6
              c4.6,0,8.3,3.7,8.3,8.3v66.1l0,11.7H215v-110C215,110,212.1,103.4,207.1,98.8z M204,215.9h-33.4l0-66.8c0-10.6-8.7-19.3-19.3-19.3
              h-45.6c-10.6,0-19.3,8.7-19.3,19.3v66.1l0,0.7H53v-99c0-3.8,1.6-7.4,4.4-10l46.8-43.1l20.6-18.7c2.1-1.9,5.3-1.9,7.4,0l20.6,18.7
              l46.9,43.1c2.8,2.6,4.4,6.2,4.4,10V215.9z"/>
          </svg>
        </div>
        <div v-if="showTutorialPopup" id="btn-tutorial" class="button" @mouseleave="onBtnTutorialLeave">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve" @click="$emit('open-tutorial')" class="highlighted">
            <path d="M224.5,109.2c0-19.7,0-39.3,0-59c0-10.4-7.3-17.6-17.7-17.6L50.9,32.5c-11.3,0-18.3,7-18.3,18.4l-0.1,154.3 c0,11.8,7,18.8,18.7,18.8l124.8,0c0.2,0,0.4,0,0.6,0l29.6,0c11.3,0,18.3-7,18.3-18.4L224.5,109.2 C224.5,109.2,224.5,109.2,224.5,109.2z M206.7,213.6l-156.2,0c-5.2,0-7.8-2.6-7.8-7.8v-13.6h0v-88.1h0.1c0-18,0-35.9,0-53.9 c0-4.6,2.8-7.3,7.5-7.3h83.5l60.4,0l12.1,0c5.3,0,7.9,2.6,7.9,8c0,11.9,0,33.6,0,37.8v76c0,0,0,0,0,0c0,13.8,0,27.7,0,41.5 C214.2,210.9,211.4,213.6,206.7,213.6z"/>
            <path d="M133.8,176v-6.4c0-1.2-1-2.2-2.2-2.2h-6.4c-1.2,0-2.2,1-2.2,2.2v6.4c0,1.2,1,2.2,2.2,2.2h6.4 C132.9,178.2,133.8,177.2,133.8,176z"/>
            <path d="M103.2,101.6c0.4,0.4,1,0.7,1.5,0.7h6.6c1.1,0,2-0.8,2.1-1.9c0.4-4.3,2.2-8.1,5.3-10.7l0.2-0.1 c0.3-0.2,0.6-0.5,0.9-0.7c2.1-1.5,4-2,4-2c3-0.9,6.1-0.9,9.3,0.1c5.6,1.8,9.6,6.7,10.3,12.5c0.7,5.6-1.3,8.9-4.2,13.3l-11,15.8 c-2.6,3.8-4.3,7.3-4.3,13v3.8c0,1.2,0.9,2.1,2.1,2.1h6.6c1.2,0,2.1-0.9,2.1-2.1v-3.7c0-1.9,0.7-5.1,2.3-7.6l11.1-16 c3.7-5.4,6.2-10.3,6.2-16.6c0-14.3-11.1-25.1-25.8-25.1c-3.5,0-6.8,0.6-9.9,1.8c-2.7,0.9-5.2,2.7-5.3,2.8l-0.1,0.1 c-6.2,4.3-10.1,11.2-10.6,18.9C102.6,100.6,102.8,101.2,103.2,101.6z"/>
          </svg>
        </div>
        <div v-else class="button" title="Aide" v-title>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve" @click="$emit('open-tutorial')">
            <path d="M224.5,109.2c0-19.7,0-39.3,0-59c0-10.4-7.3-17.6-17.7-17.6L50.9,32.5c-11.3,0-18.3,7-18.3,18.4l-0.1,154.3 c0,11.8,7,18.8,18.7,18.8l124.8,0c0.2,0,0.4,0,0.6,0l29.6,0c11.3,0,18.3-7,18.3-18.4L224.5,109.2 C224.5,109.2,224.5,109.2,224.5,109.2z M206.7,213.6l-156.2,0c-5.2,0-7.8-2.6-7.8-7.8v-13.6h0v-88.1h0.1c0-18,0-35.9,0-53.9 c0-4.6,2.8-7.3,7.5-7.3h83.5l60.4,0l12.1,0c5.3,0,7.9,2.6,7.9,8c0,11.9,0,33.6,0,37.8v76c0,0,0,0,0,0c0,13.8,0,27.7,0,41.5 C214.2,210.9,211.4,213.6,206.7,213.6z"/>
            <path d="M133.8,176v-6.4c0-1.2-1-2.2-2.2-2.2h-6.4c-1.2,0-2.2,1-2.2,2.2v6.4c0,1.2,1,2.2,2.2,2.2h6.4 C132.9,178.2,133.8,177.2,133.8,176z"/>
            <path d="M103.2,101.6c0.4,0.4,1,0.7,1.5,0.7h6.6c1.1,0,2-0.8,2.1-1.9c0.4-4.3,2.2-8.1,5.3-10.7l0.2-0.1 c0.3-0.2,0.6-0.5,0.9-0.7c2.1-1.5,4-2,4-2c3-0.9,6.1-0.9,9.3,0.1c5.6,1.8,9.6,6.7,10.3,12.5c0.7,5.6-1.3,8.9-4.2,13.3l-11,15.8 c-2.6,3.8-4.3,7.3-4.3,13v3.8c0,1.2,0.9,2.1,2.1,2.1h6.6c1.2,0,2.1-0.9,2.1-2.1v-3.7c0-1.9,0.7-5.1,2.3-7.6l11.1-16 c3.7-5.4,6.2-10.3,6.2-16.6c0-14.3-11.1-25.1-25.8-25.1c-3.5,0-6.8,0.6-9.9,1.8c-2.7,0.9-5.2,2.7-5.3,2.8l-0.1,0.1 c-6.2,4.3-10.1,11.2-10.6,18.9C102.6,100.6,102.8,101.2,103.2,101.6z"/>
          </svg>
        </div>
        <div class="button" title="Catalogue" v-title>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 457.35 256" style="enable-background:new 0 0 457.35 256;" xml:space="preserve" @click="$emit('open-catalogue')">
            <path d="M266.51,201.72h-81.28c-9.8,0-17.78-7.98-17.78-17.78V73.92c0-9.8,7.98-17.76,17.78-17.76h81.28
              c9.8,0,17.76,7.96,17.76,17.76v110.03C284.26,193.74,276.3,201.72,266.51,201.72z M185.23,65.59c-4.6,0-8.35,3.73-8.35,8.33v110.03
              c0,4.6,3.75,8.35,8.35,8.35h81.28c4.6,0,8.33-3.75,8.33-8.35V73.92c0-4.6-3.73-8.33-8.33-8.33H185.23z"/>
            <path d="M400.11,201.72h-81.28c-9.8,0-17.76-7.98-17.76-17.78V73.92c0-9.8,7.96-17.76,17.76-17.76h81.28
              c9.8,0,17.78,7.96,17.78,17.76v110.03C417.89,193.74,409.9,201.72,400.11,201.72z M318.83,65.59c-4.6,0-8.33,3.73-8.33,8.33v110.03
              c0,4.6,3.73,8.35,8.33,8.35h81.28c4.6,0,8.35-3.75,8.35-8.35V73.92c0-4.6-3.75-8.33-8.35-8.33H318.83z"/>
            <path d="M133.71,201.72h-81.3c-9.8,0-17.76-7.98-17.76-17.78V73.92c0-9.8,7.96-17.76,17.76-17.76h81.3
              c9.8,0,17.76,7.96,17.76,17.76v110.03C151.47,193.74,143.51,201.72,133.71,201.72z M52.41,65.59c-4.6,0-8.33,3.73-8.33,8.33v110.03
              c0,4.6,3.73,8.35,8.33,8.35h81.3c4.6,0,8.33-3.75,8.33-8.35V73.92c0-4.6-3.73-8.33-8.33-8.33H52.41z"/>
            <rect x="29.07" y="214.81" width="394.41" height="9.43"/>
            <rect x="29.07" y="34.06" width="394.41" height="9.43"/>
          </svg>
        </div>
        <div class="button" title="Plan" v-title>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve" @click="$emit('open-map')">
            <path d="M200.15,100.11h14.74v16.56c0,11.25,0.02,22.52-0.02,33.79c0,5.08-2.83,7.89-7.96,7.89
              c-8.33-0.02-16.63,0.02-24.95,0v-2.55c0-5.22-2.81-6.37-5.13-6.42c-1.73-0.23-3.22,0.69-3.89,1.38c-1.61,1.54-1.61,3.7-1.61,5.13
              l0.02,22.33c0.05,4.12,1.79,6.23,5.17,6.35c0.05,0,0.09,0,0.14,0c1.89,0,3.06-0.76,3.73-1.38c1.56-1.54,1.56-3.7,1.56-5.15v-9.11
              l7.98,0.02c5.93,0,11.84,0.05,17.78-0.05c10.21-0.14,17.64-7.59,17.66-17.71c0.07-33.85,0.09-67.73,0.07-101.59
              c-0.02-10.67-7.52-18.1-18.22-18.1L46.81,31.39c-11.61,0-18.84,7.22-18.86,18.88l-0.07,158.72c0,12.12,7.2,19.37,19.25,19.37
              l128.33,0.02c5.11,0,6.26-2.67,6.35-4.9c0.16-5.61,0.16-11.18-0.02-16.51c-0.05-1.7-1.36-2.99-2.12-3.59
              c-1.2-0.97-2.37-1.33-3.59-1.1c-2.05,0.41-4.44,2.55-4.58,4.85c-0.21,2.48-0.18,5.01-0.16,7.52v2.83c0,0.02,0,0.02,0,0.05h-39.44
              v-4.83l0.02-28.29h0.03l-0.01-3.13c0.04-0.5,0.08-1.14-0.03-1.8c-0.19-2.13-1.13-5.7-5.26-5.7c-3.96,0-4.94,3.27-5.17,5.42
              c-0.21,0.84-0.14,1.67-0.08,2.42v30.69v5.38c-0.02,0-0.07,0-0.09,0l-74.86-0.02c-5.4,0-8.03-2.62-8.03-8.05V146.8l82.27,0.05
              c0.24,0,0.45,0,0.63-0.02l0.01,2.59c-0.02,0.24-0.02,0.51,0,0.79c-0.04,0.71-0.25,7.17,5.3,7.17c5.88,0,5.21-7.24,5.21-7.24
              l-0.01-0.81c0.14-4.69,0.15-9.44,0.03-14.14l-0.01-2.21c0,0,0.58-7.24-5.3-7.24s-5.21,7.24-5.21,7.24l0.01,0.75
              c-0.03,0.59,0,1.14,0.02,1.62c0,0.35,0.02,0.71,0.02,1.01c-1.56,0.09-3.1,0.05-4.76,0.05l-78.2-0.02c0-28.93,0-57.87,0.05-86.8
              c0-4.69,2.9-7.5,7.75-7.5h75.16v2.54v57.63c-0.02,1.4-0.05,3.5,1.5,5.04c0.64,0.64,1.82,1.38,3.7,1.38c0.07,0,0.14,0,0.18,0
              c2.39-0.07,5.24-1.22,5.13-6.85c0.02-0.71,0.02-1.4,0.02-2.12c2.3-0.02,4.44,0,6.6,0.02c0.07,0,0.14,0,0.18,0
              c5.38,0,6.28-3.2,6.35-5.11c0.07-1.98-0.74-3.22-1.4-3.91c-1.52-1.52-3.61-1.7-5.11-1.66h-6.42V50.59v-8.51l74.63,0.02
              c5.47,0,8.14,2.69,8.14,8.26c0.02,14.83-0.02,44.62-0.02,38.87h-14.74h-35.82c-1.31,0.02-3.66-0.07-5.27,1.54
              c-0.67,0.67-1.47,1.89-1.45,3.89c0.02,1.98,0.85,3.15,1.52,3.79c1.56,1.52,3.68,1.38,5.68,1.43h6.53l-0.02,24.68
              c0,1.08,0,3.33,1.61,4.92c0.64,0.64,1.82,1.4,3.68,1.4c0.02,0,0.05,0,0.07,0c1.98-0.02,5.27-0.87,5.27-6.37
              c-0.02-8.12-0.02-16.26,0-24.4C181.96,100.11,200.15,100.11,200.15,100.11z"/>
          </svg>
        </div>
        <div class="button" title="Plein écran" v-title>
          <svg v-if="isFullscreen" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;"
            xml:space="preserve" @click="$emit('toggle-fullscreen')">
            <path d="m 56.99,109.65 c -3.31,0 -6,2.69 -6,6 0,3.31 2.69,6 6,6 h 53.87 c 3.31,0 6,-2.69 6,-6 V 62.2 c 0,-3.31 -2.69,-6 -6,-6 -3.31,0 -6,2.69 -6,6 v 39.05 l -62.87,-62.31 -8.45,8.52 62.74,62.19 z" />
            <path d="m 143.96,121.64 h 53.86 c 3.31,0 6,-2.69 6,-6 0,-3.31 -2.69,-6 -6,-6 h -39.29 l 62.74,-62.18 -8.45,-8.52 -62.87,62.31 V 62.2 c 0,-3.31 -2.69,-6 -6,-6 -3.31,0 -6,2.69 -6,6 v 53.44 c 0.01,3.31 2.7,6 6.01,6 z" />
            <path d="m 41.99,225.14 62.87,-62.31 v 39.05 c 0,3.31 2.69,6 6,6 3.31,0 6,-2.69 6,-6 v -53.45 c 0,-3.31 -2.69,-6 -6,-6 H 57 c -3.31,0 -6,2.69 -6,6 0,3.31 2.69,6 6,6 h 39.28 l -62.74,62.18 z" />
            <path d="m 143.93003,207.87 c 3.31,0 6,-2.69 6,-6 v -39.05 l 62.87,62.31 8.45,-8.52 -62.74,-62.18 h 39.29 c 3.31,0 6,-2.69 6,-6 0,-3.31 -2.69,-6 -6,-6 h -53.86 c -3.31,0 -6,2.69 -6,6 v 53.45 c -0.01,3.31 2.68,5.99 5.99,5.99 z" />
          </svg>
          <svg v-else version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;"
            xml:space="preserve" @click="$emit('toggle-fullscreen')">
            <path class="st0" d="M93.41,50.94c3.31,0,6-2.69,6-6s-2.69-6-6-6H39.54c-3.31,0-6,2.69-6,6v53.45c0,3.31,2.69,6,6,6s6-2.69,6-6 V59.34l62.87,62.31l8.45-8.52L54.12,50.94H93.41z"/>
            <path class="st0" d="M215.26,38.94h-53.86c-3.31,0-6,2.69-6,6s2.69,6,6,6h39.29l-62.74,62.18l8.45,8.52l62.87-62.31v39.05 c0,3.31,2.69,6,6,6s6-2.69,6-6V44.94C221.26,41.63,218.57,38.94,215.26,38.94z"/>
            <path class="st0" d="M108.41,142.43l-62.87,62.31v-39.05c0-3.31-2.69-6-6-6s-6,2.69-6,6v53.45c0,3.31,2.69,6,6,6h53.86 c3.31,0,6-2.69,6-6s-2.69-6-6-6H54.12l62.74-62.18L108.41,142.43z"/>
            <path class="st0" d="M215.26,159.69c-3.31,0-6,2.69-6,6v39.05l-62.87-62.31l-8.45,8.52l62.74,62.18h-39.29c-3.31,0-6,2.69-6,6 s2.69,6,6,6h53.86c3.31,0,6-2.69,6-6v-53.45C221.26,162.37,218.57,159.69,215.26,159.69z"/>
          </svg>
        </div>
      </div>
      <div v-else>
        <div class="button" title="Dézoomer" v-title>
          <svg id="btn-zoom-out" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
            <path d="m 128.01,38.89 c 49.8,0 90.31,40.51 90.31,90.31 0,49.8 -40.51,90.31 -90.31,90.31 -49.8,0 -90.3,-40.52 -90.3,-90.32 0,
              -49.8 40.5,-90.3 90.3,-90.3 m 0,-8 c -54.29,0 -98.31,44.01 -98.31,98.31 0,54.3 44.01,98.31 98.31,98.31 54.3,0 98.31,-44.01 98.31,
              -98.31 0,-54.3 -44.02,-98.31 -98.31,-98.31 z" />
            <path d="m 77.880859,124.18945 a 5,5 0 0 0 -5,5 5,5 0 0 0 5,5 H 178.13086 a 5,5 0 0 0 5,-5 5,5 0 0 0 -5,-5 z" />
          </svg>
        </div>
        <div class="button" title="Zoomer" v-title>
          <svg id="btn-zoom-in" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
            <path d="m 128.01,38.89 c 49.8,0 90.31,40.51 90.31,90.31 0,49.8 -40.51,90.31 -90.31,90.31 -49.8,0 -90.3,-40.52 -90.3,-90.32 0,
              -49.8 40.5,-90.3 90.3,-90.3 m 0,-8 c -54.29,0 -98.31,44.01 -98.31,98.31 0,54.3 44.01,98.31 98.31,98.31 54.3,0 98.31,-44.01 98.31,
              -98.31 0,-54.3 -44.02,-98.31 -98.31,-98.31 z" />
            <path d="m 77.880859,124.18945 a 5,5 0 0 0 -5,5 5,5 0 0 0 5,5 H 178.13086 a 5,5 0 0 0 5,-5 5,5 0 0 0 -5,-5 z" />
            <path d="m 128.00977,74.070312 a 5,5 0 0 0 -5,5 V 179.32031 a 5,5 0 0 0 5,5 5,5 0 0 0 5,-5 V 79.070312 a 5,5 0 0 0 -5,-5 z" />
          </svg>
        </div>
        <div class="spacer"></div>
        <div class="button" title="Retour à la fiche" v-title @click="closeZoomIn">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
            <path d="m 128.01,38.89 c 49.8,0 90.31,40.51 90.31,90.31 0,49.8 -40.51,90.31 -90.31,90.31 -49.8,0 -90.3,-40.52 -90.3,-90.32 0,
              -49.8 40.5,-90.3 90.3,-90.3 m 0,-8 c -54.29,0 -98.31,44.01 -98.31,98.31 0,54.3 44.01,98.31 98.31,98.31 54.3,0 98.31,-44.01 98.31,
              -98.31 0,-54.3 -44.02,-98.31 -98.31,-98.31 z" />
            <path d="m 96.102633,90.212312 c -6.281993,-5.431895 -11.534269,4.986229 -5.615017,8.527116 23.143804,23.143802 46.287604,46.287602 69.431404,69.431402 5.50094,5.37166 11.9592,-4.22194 6.26215,-7.87999 -23.35951,-23.35951 -46.71902,-46.71902 -70.078537,-70.078528 z" />
            <path d="m 166.98871,90.219224 c -3.44806,-3.469507 -7.27377,-0.101914 -9.6596,2.588528 -22.76631,22.766308 -45.53262,45.532608 -68.298922,68.298918 -5.431914,6.282 4.986225,11.53428 8.527116,5.61502 23.143806,-23.1438 46.287606,-46.2876 69.431406,-69.431397 1.9399,-1.857486 1.94038,-5.212785 0,-7.071069 z" />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="showTutorialPopup" ref="tutorial-popup" class="custom-popup-bubble popup-bubble-block popup-bubble-upper" position="top">Aide</div>
  </div>
</template>

<script>
import cameraData from '@/assets/data/camera_data.json';

export default {
  name: 'BottomBar',
  props: {
    isFullscreen: { type: Boolean, required: true },
    inUI: { type: Boolean, required: true },
  },
  computed: {
    frame() { return this.$store.state.frame },
    zoomedIn() { return this.$store.state.zoomedIn; },
    showTutorialPopup() { return this.$store.state.showTutorialPopup; },
    roomName() {
      const { room } = cameraData.frame_info.find((f) => f.frame === this.frame);
      return cameraData.room_info[`s${room.toString().padStart(2, '0')}`].name;
    },
  },
  watch: {
    showTutorialPopup(show) {
      if (!show) return;
      const $this = this;
      this.$nextTick(() => {
        const el = document.getElementById('btn-tutorial');
        const bubble = $this.$refs['tutorial-popup'];
        const elOffset = el.offset(),
              top = elOffset.top,
              left = elOffset.left,
              width = el.offsetWidth,
              docScrollTop = document.documentElement.scrollTop,
              docScrollLeft = document.documentElement.scrollLeft,
              bubbleWidth = bubble.offsetWidth,
              bubbleHeight = bubble.offsetHeight,
              bubbleLeft = left - docScrollLeft - ((bubbleWidth - width) / 2);
        bubble.style.left = `${bubbleLeft}px`;
        bubble.style.top = `${top - docScrollTop - bubbleHeight - 4}px`;
      })
    },
  },
  methods: {
    closeZoomIn() {
      this.$store.commit('setZoomedIn', { zoomedIn: false });
    },
    onBtnTutorialLeave() {
      if (this.showTutorialPopup) {
        this.$store.commit('setShowTutorialPopup', { showTutorialPopup: false });
      }
    },
  },
}
</script>

<style>
#bottom-bar {
  --bar-height: 36px;
  position: absolute;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--bar-height);
  padding: 0.5rem 2rem;
  cursor: var(--cursor-hand-base--white);
}

#bottom-bar__info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}
#bottom-bar__info > div {
  display: flex;
  align-items: center;
}
#bottom-bar .logo-image {
  width: auto;
  height: var(--bar-height);
}
#bottom-bar .logo-title {
  font-family: "ArimaMadurai-Regular";
  text-transform: uppercase;
  color: var(--c-secondary);
  margin-left: 1rem;
}
#bottom-bar .button svg {
  height: var(--bar-height);
  fill: var(--c-secondary);
  outline: none;
}
#bottom-bar .button svg {
  cursor: var(--cursor-hand-click--white);
}
#bottom-bar .button:not(:last-child) {
  margin-right: 0.5rem;
}
#bottom-bar .spacer {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
#bottom-bar .button:hover svg {
  height: var(--bar-height);
  fill: var(--c-secondary--hover);
}
#bottom-bar .button svg.highlighted {
  stroke: var(--c-secondary--hover);
  stroke-width: 4;
}

#bottom-bar__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: linear-gradient(transparent, #252626);
  pointer-events: none;
}

.custom-popup-bubble {
  font-family: Alegreya;
  font-size: 17px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  text-align: center;
  padding: 5px 10px;
  color: #fff;
  white-space: nowrap;

  position: fixed;
  /* top: -4px;
  left: 50%;
  transform: translate(-50%, -100%); */
}
</style>
