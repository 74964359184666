<template>
  <div>
    <div id="ui-overlay" @click="$emit('close')"></div>
    <div id="intro-panel">
      <div class="catalogue-section">
        <img id="logo" src="logo-ijv_bleu.png" width="64" height="auto" />
        <div id="catalogue-room-text">
          <div id="catalogue-room-text--content">
            <div class="catalogue-separator"></div>
            <h1>Bienvenue au musée virtuel de l'affiche de cinéma</h1>
            <div v-html="text"></div>

            <div class="intro-section">Aide à la navigation</div>
            <div class="intro-subsection">Interactions dans l'espace de l'exposition</div>
              <img class="popup-img" src="vigo-tuto1.jpg" />
            <div class="intro-subsection">Interactions dans l'écran "œuvre"</div>
              <img class="popup-img" src="vigo-tuto2.jpg" />
              
            <div class="intro-section">Crédits</div>
            <div id="intro-credits">
              <div>
                <div class="intro-subsection">Institut Jean Vigo</div>
                <div class="title">Coordination</div>
                <div>Kees Bakker, Laurent Ballester</div>
                <div class="title">Commissariat d'exposition</div>
                <div>Laurent Ballester</div><br />
                <div>Conception à partir des expositions :</div>
                <div>• <i>1892 – 1929 : L’affiche invente le cinéma</i> (Institut Jean Vigo, 2016)</div>
                <div>• <i>Le cinéma s’affiche en grand</i> (Institut Jean Vigo, 2015)</div>
                <div class="title">Textes</div>
                <div>Julien Avet, Kees Bakker, Laurent Ballester, Frédéric Borgia, François de la Bretèque, Michel Cadé, Alain Carou, Marc Henri Crave, Régis Fromaget, Béatrice de Pastre, Jacques Verdier</div>
              </div>
              <div>
                <div class="intro-subsection">Les Fées Spéciales</div>
                <div class="title">Direction artistique</div>
                <div>Marie Saby, Eric Serre</div>
                <div class="title">Développement</div>
                <div>Mina Pêcheux</div>
                <div class="title">Modélisation, éclairage, rendu</div>
                <div>Damien Picard</div>
                <div class="title">Ingénieure de recherche</div>
                <div>Nathalie Combe</div>
                <div class="title">Chargée de production</div>
                <div>Natalène Darfeuille</div>
                <div class="title">Co-gérants</div>
                <div>Sophie Marron, Flavio Perez, Eric Serre</div>
              </div>
            </div>
            <br />
            <div class="intro-subsection">Avec le soutien de la DRAC Occitanie</div>

            <div id="intro-btn-wrapper">
              <button id="intro-btn" @click="$emit('close')">
                <div id="intro-btn-text">commencer la visite</div>
                <div id="intro-btn-icon">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                    <path d="M 213.31281,121.36731 151.6672,59.721699 c -3.78848,-3.788471 -9.94617,-3.788471 -13.73465,0 -3.78846,3.788469 -3.78846,9.946163 0,13.734633 l 44.96949,44.969478 -142.977534,-0.64095 -0.08012,19.42306 143.275114,0.64094 -44.69479,44.69479 c -3.78846,3.78848 -3.78846,9.94617 0,13.73464 3.78847,3.78847 9.94617,3.78847 13.73464,0 l 61.1649,-61.16489 c 3.77703,-3.79993 3.77703,-9.95762 -0.0113,-13.74609 z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import postersData from '@/assets/data/posters.json';

export default {
  name: 'IntroPanel',
  computed: {
    text() {
      return postersData.s00.texte;
    },
  },
}
</script>

<style>
#intro-panel {
  --pad: 10vh;
  --bottom-offset: 4rem + 32px;
  position: absolute;
  z-index: 9999;
  max-width: 780px;
  margin: 0 auto;
  height: calc(100vh - 2 * var(--pad) - (var(--bottom-offset)));
  right: 0;
  left: 0;
  pointer-events: none;
}

#intro-panel .catalogue-section {
  margin: 0 auto;
  grid-template-columns: auto 1fr;
}

#intro-panel #logo {
  justify-self: flex-end;
  align-self: flex-start;
  margin-right: 3rem;
  cursor: var(--cursor-hand-base--white);
  pointer-events: all;
}

#intro-panel #catalogue-room-text {
  height: calc(100vh - var(--pad) * 2 - (var(--bottom-offset)));
  cursor: var(--cursor-hand-base--white);
  pointer-events: all;
}

.intro-section {
  padding-top: 2rem;
  font-weight: bold;
  font-size: 1.6rem;
  text-transform: uppercase;
  border-top: 1px solid var(--c-secondary);
  margin-top: 2rem;
}
.catalogue-separator+.intro-section {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

#intro-panel .intro-subsection {
  margin: 0.5rem 0;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
}

#intro-panel #catalogue-room-text--content {
  padding-bottom: 1rem;
}

#intro-panel #catalogue-room-text--content a {
  color: currentColor;
  cursor: var(--cursor-hand-click--white);
}

#intro-panel .intro-subsection+p {
  margin-top: 0;
}

#intro-panel #catalogue-room-text--content h1 {
  text-transform: uppercase;
}

#intro-credits {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  text-align: left;
}
#intro-credits .title {
  font-size: 1.2rem;
  margin-top: 1rem;
}

#intro-panel #intro-btn-wrapper {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--c-secondary);
  position: absolute;
  bottom: 0;
  left: calc(64px + 3rem);
  right: 4rem;
  transform: translateY(100%);
}

#intro-panel #intro-btn {
  --btn-height: 32px;
  width: 50%;
  height: var(--btn-height);
  background-color: var(--c-secondary);
  color: #FCF6F2;
  border: 0;
  border-radius: 9999px;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr auto;
  margin: 0 auto;
  text-transform: uppercase;
  padding: 0;
  font-size: 1.2rem;
  font-family: 'ArimaMadurai-Regular';
  font-weight: 600;
  cursor: var(--cursor-hand-click--white);
  padding-right: calc(var(--btn-height) * 0.1);
  padding-left: calc(var(--btn-height) * 0.3);
}
#intro-panel #intro-btn:hover {
  background-color: var(--c-tertiary);
}
#intro-panel #intro-btn:active {
  background-color: var(--c-tertiary--dark);
}

#intro-panel #intro-btn-text {
  text-align: left;
  width: 100%;
  height: 90%;
}

#intro-panel #intro-btn-icon {
  border-radius: 50%;
  background-color: #111111;
  width: calc(var(--btn-height) * 0.8);
  height: calc(var(--btn-height) * 0.8);
}
#intro-panel #intro-btn-icon svg {
  fill: #FCF6F2;
}
</style>
