<template>
  <div id="main-container" tabindex="0" @keydown.esc="onEscape">
    <div v-if="inUI">
      <div id="ui-overlay" @click="onCloseUI"></div>
      <Catalogue
        :room="uiRoomId"
        @close="onCloseUI"
      />
    </div>
    <div v-if="inMap">
      <div id="ui-overlay" @click="onCloseUI"></div>
      <Map @close="onCloseUI" @switch-to-room="switchToRoom"></Map>
    </div>
    <BottomBar
      @open-intro="showIntro = true"
      @open-tutorial="onTutorialOpen"
      @open-catalogue="openCatalogue"
      @open-map="openMap"
      @toggle-fullscreen="toggleFullscreen"
      :isFullscreen="isFullscreen"
      :inUI="inUI"
    />
    <ThreeJSEnv ref="threejs" @click-poi="onClickPOI" />
    <IntroPanel v-if="showIntro" @close="onIntroClose" />
    <TutorialPanel v-if="showTutorial" @close="showTutorial = false" />
    <div id="transition-screen" :class="{ visible: inTransition }"></div>
  </div>
</template>

<script>
import roomToFrame from '@/assets/data/room_to_frame.json';

import BottomBar from './BottomBar.vue';
import Catalogue from './Catalogue.vue';
import IntroPanel from './IntroPanel.vue';
import Map from './Map.vue';
import ThreeJSEnv from './ThreeJSEnv.vue';
import TutorialPanel from './TutorialPanel.vue';

export default {
  name: 'MainContainer',
  components: { BottomBar, Catalogue, IntroPanel, Map, ThreeJSEnv, TutorialPanel },
  data() {
    return {
      showIntro: true,
      showTutorial: false,
      isFullscreen: false,     
      inUI: false,
      inMap: false,
      uiRoomId: null,
    }
  },
  mounted() {
    document.getElementById('main-container').focus();
  },
  computed: {
    inTransition() { return this.$store.state.inTransition; },
  },
  methods: {
    onIntroClose() {
      this.showIntro = false;

      let showTutorialPopup = true;
      const alreadyShown = window.localStorage.getItem('__ijv__shown_tutorial_popup');
      if (alreadyShown) showTutorialPopup = false;
      this.$store.commit('setShowTutorialPopup', { showTutorialPopup });
      window.localStorage.setItem('__ijv__shown_tutorial_popup', true);
    },
    onTutorialOpen() {
      this.showTutorial = true;
      this.$store.commit('setShowTutorialPopup', { showTutorialPopup: false });
    },

    onEscape() {
      if (this.showIntro) this.showIntro = false;
      if (this.inUI || this.inMap) this.onCloseUI();
    },
    onCloseUI() {
      this.inUI = false;
      this.inMap = false;
      this.$store.commit('setZoomedIn', { zoomedIn: false });
    },

    onClickPOI({ id, type }) {
      this.inUI = true;
      if (type === 'room') {
        this.uiRoomId   = id;
        this.$store.commit('setUiPoster', { uiPoster: null });
      }
      else if (type === 'poster' || type === 'label') {
        this.uiRoomId   = null;
        this.$store.commit('setUiPoster', { uiPoster: id });
      }
      this.$store.commit('setShowTutorialPopup', { showTutorialPopup: false });
    },

    openCatalogue() {
      this.inUI = true;
      this.inMap = false;
      this.uiRoomId = null;
      this.$store.commit('setUiPoster', { uiPoster: null });
      this.$store.commit('setShowTutorialPopup', { showTutorialPopup: false });
    },
    openMap() {
      this.inUI = false;
      this.inMap = true;
      this.uiRoomId = null;
      this.$store.commit('setUiPoster', { uiPoster: null });
      this.$store.commit('setShowTutorialPopup', { showTutorialPopup: false });
    },
    toggleFullscreen() {
      if (!this.isFullscreen) {  // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
      this.isFullscreen = !this.isFullscreen;
      this.$store.commit('setShowTutorialPopup', { showTutorialPopup: false });
    },

    switchToRoom(room) {
      this.onCloseUI();
      this.$refs.threejs.setLonLat(roomToFrame[room].lon, roomToFrame[room].lat);
      this.$store.commit('setFrame', { frame: roomToFrame[room].frame, force: true });
    },
  },
}
</script>

<style>
#main-container {
  overflow: hidden;
}

#ui-overlay,
#transition-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #252626;
}
#ui-overlay {
  z-index: 9999;
  opacity: 0.7;
  cursor: var(--cursor-close);
}

#transition-screen {
  z-index: 10000;
  opacity: 0;
  transition: opacity 0.4s linear;
  pointer-events: none;
}
#transition-screen.visible {
  z-index: 10000;
  opacity: 1;
  pointer-events: all;
}
</style>
